import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"
// images

const Epic = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">
                Energy Program for Innovation Clusters (EPIC) Prize
              </h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Challenges’ Energy Program for Innovation
                Clusters (EPIC) Prize recognizes the nation’s most innovative
                incubators. Sponsored by the Office of Technology Transitions at
                the U.S. Department of Energy, EPIC will award prizes to
                competitors that submit the most creative and impactful plans to
                develop strong clusters, connections, and support for startups
                and entrepreneurs whose businesses focus on energy-related
                technologies.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/epic">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <p className="pcol-fz">
            Part of the American-Made Challenges series, the EPIC Prize asks
            incubators to develop strategic plans to create or bolster
            innovation clusters supporting startups and entrepreneurs whose
            businesses focus on energy-related technologies. The prize
            emphasizes regional engagement, including with private actors—both
            for- and non-profit—as well as state and local entities that can
            provide additional resources.
          </p>
          <p className="pcol-fz">
            Incubators are critical to the growth of an innovation ecosystem;
            they help startups and entrepreneurs accelerate the launch, growth,
            and scale of their businesses, ultimately resulting in the
            development of jobs in the United States. The prize seeks to address
            a capital gap in supporting these critical organizations by
            providing funds to be applied where incubators need them most.
            Providing capital to enable creative, forward-thinking organizations
            is critical to allow these groups to think more expansively about
            the role of incubators in the broader regional innovation and
            entrepreneurial ecosystem. Moreover, it is DOE’s hope that this
            funding will enable prize winners to enhance their organization’s
            resilience and operational sustainability.
          </p>
          <span style={{ padding: "5px" }}></span>
          <h1 className="mac_h1">Prize Structure</h1>
          <div>
            <p className="pcol-fz">
              Competitors will create presentation slides describing their
              strategic plan for regional cluster development. The plan should
              focus on how the incubator will develop relationships with
              regional entities to form innovation clusters, and the impact such
              a plan may have on the industry. Competitors must also submit a
              short marketing video that may be used when announcing winners. Up
              to 20 winning teams will be awarded $50,000 each.
            </p>
          </div>
          <hr />
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Prize Announced: </b>
                July 20, 2020
              </li>
              <li>
                <b>Webinar – Competing in the EPIC Prize:</b>
                August, 04 2020
              </li>
              <li>
                <b>Submissions Due: </b>
                September, 10 2020
              </li>
              <li>
                <b>Announcement of Winners: </b>
                Anticipated by September 30, 2020
              </li>
            </ul>
            <hr />
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/epic"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
            <b class="is-size-7">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/epic">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Epic
